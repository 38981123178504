import async from "../components/Async";
import * as types from "./../redux/constants";

import {
  Activity as ActivityIcon,
  AlertTriangle as AlertTriangleIcon,
  Bell as Bellicon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Eye as EyeIcon,
  TrendingUp as TrendingUpIcon,
  BarChart as BarChartIcon,
  BarChart2 as BarChart2Icon,
  Calendar as CalendarIcon,
  Settings as SettingsIcon,
  Monitor as MonitorIcon
} from "react-feather";

// auth
import SignIn from "../pages/auth/SignIn";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// notifications
import Notifications from "../pages/notifications/Notifications";

// documentation
import TermsOfService from "../pages/docs/TermsOfService";

// dashboards
const Cockpit = async(() => import("../pages/dashboards/Cockpit"));
const ProRataOverview = async(() => import("../pages/dashboards/ProRataOverview"))
const IntentionsOverview = async(() => import("../pages/dashboards/IntentionsOverview"));
const PnLOverview = async(() => import("../pages/dashboards/PnLOverview"));
const CapitalAccounts = async(() => import("../pages/dashboards/CapitalAccounts"));
const RiskMonitor = async(() => import("../pages/dashboards/RiskMonitor"));
const RiskControls = async(() => import("../pages/dashboards/RiskControls"));
const LogVisualiser = async(() => import("../pages/dashboards/LogVisualiser"));
const LogParser = async(() => import("../pages/dashboards/LogParser"));
const OrderAnalyser = async(() => import("../pages/dashboards/OrderAnalyser"));
const TradeMonitor = async(() => import("../pages/dashboards/TradeMonitor"));
const MacroEvents = async(() => import("../pages/dashboards/MacroEvents"));
const ScheduledEvents = async(() => import("../pages/dashboards/ScheduledEvents"));
const LiveViews = async(() => import("../pages/dashboards/LiveViews"));
const ViewsAnalyser = async(() => import ("../pages/dashboards/ViewsAnalyser"));
const ViewsEditor = async(() => import ("../pages/dashboards/ViewsEditor"));

// routes
const termsRoutes = {
  path: types.REACT_ROOT + types.TERMS,
  name: "Terms of Service",
  component: TermsOfService,
  children: null
};

const authRoutes = {
  path: types.REACT_ROOT + types.AUTH,
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: types.REACT_ROOT + types.SIGN_IN,
      name: "Sign In",
      component: SignIn
    },
    {
      path: types.REACT_ROOT + types.PAGE_404,
      name: "404 Page",
      component: Page404
    },
    {
      path: types.REACT_ROOT + types.PAGE_500,
      name: "500 Page",
      component: Page500
    }
  ]
};

const notificationsRoutes = {
  path: types.REACT_ROOT + types.NOTIFICATIONS,
  name: "Notifications",
  icon: Bellicon,
  component: Notifications,
  children: null
};

// const eurodollarRoutes = {
//   path: '/ed',
//   name: "Eurodollar Futures",
//   icon: ActivityIcon,
//   children: [
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.CONTROL,
//       name: "Cockpit",
//       component: Cockpit,
//     },
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.TRADE_MONITOR,
//       name: "Trade Monitor",
//       component: TradeMonitor
//     },
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.RISK_MONITOR,
//       name: "Risk Monitor",
//       component: RiskMonitor
//     },     
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.PRORATA,
//       name: "Pro Rata Overview",
//       component: ProRataOverview
//     },
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.INTENTIONS,
//       name: "Intentions Overview",
//       component: IntentionsOverview
//     },
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.PNL,
//       name: "PnL Overview",
//       component: PnLOverview
//     },
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.ORDER_ANALYSER,
//       name: "Order Analyser",
//       component: OrderAnalyser,
//     },
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.VIEWS_HISTORY,
//       name: "Views Analyser",
//       component: ViewsAnalyser
//     },
//     {
//       path: types.REACT_ROOT + types.EURODOLLAR + types.LIVE_VIEWS,
//       name: "Live Views",
//       component: LiveViews
//     }
//   ]
// };

const sofrRoutes = {
  path: '/sofr',
  name: "SOFR Futures",
  icon: ActivityIcon,
  children: [
    {
      path: types.REACT_ROOT + types.SOFR + types.CONTROL,
      name: "Cockpit",
      component: Cockpit,
    },
    {
      path: types.REACT_ROOT + types.SOFR + types.TRADE_MONITOR,
      name: "Trade Monitor",
      component: TradeMonitor
    },
    {
      path: types.REACT_ROOT + types.SOFR + types.RISK_MONITOR,
      name: "Risk Monitor",
      component: RiskMonitor
    },     
    {
      path: types.REACT_ROOT + types.SOFR + types.PRORATA,
      name: "Pro Rata Overview",
      component: ProRataOverview
    },
    {
      path: types.REACT_ROOT + types.SOFR + types.INTENTIONS,
      name: "Intentions Overview",
      component: IntentionsOverview
    },
    {
      path: types.REACT_ROOT + types.SOFR + types.PNL,
      name: "PnL Overview",
      component: PnLOverview
    },
    {
      path: types.REACT_ROOT + types.SOFR + types.ORDER_ANALYSER,
      name: "Order Analyser",
      component: OrderAnalyser,
    },
    // {
    //   path: types.REACT_ROOT + types.SOFR + types.VIEWS_HISTORY,
    //   name: "Views Analyser",
    //   component: ViewsAnalyser
    // },
    // {
    //   path: types.REACT_ROOT + types.SOFR + types.LIVE_VIEWS,
    //   name: "Live Views",
    //   component: LiveViews
    // }
  ]
};

const allMarketRoutes = {
  path: '/all',
  name: "All Markets",
  icon: SlidersIcon,
  children: [
    {
      path: types.REACT_ROOT + types.ALL_MARKETS + types.CONTROL,
      name: "Cockpit",
      component: Cockpit,
    },
    {
      path: types.REACT_ROOT + types.ALL_MARKETS + types.TRADE_MONITOR,
      name: "Trade Monitor",
      component: TradeMonitor
    },
    {
      path: types.REACT_ROOT + types.ALL_MARKETS + types.RISK_MONITOR,
      name: "Risk Monitor",
      component: RiskMonitor
    },     
    {
      path: types.REACT_ROOT + types.ALL_MARKETS + types.PRORATA,
      name: "Pro Rata Overview",
      component: ProRataOverview
    },
    {
      path: types.REACT_ROOT + types.ALL_MARKETS + types.INTENTIONS,
      name: "Intentions Overview",
      component: IntentionsOverview
    },
    {
      path: types.REACT_ROOT + types.ALL_MARKETS + types.PNL,
      name: "PnL Overview",
      component: PnLOverview
    },
    {
      path: types.REACT_ROOT + types.ALL_MARKETS + types.ORDER_ANALYSER,
      name: "Order Analyser",
      component: OrderAnalyser,
    },
    // {
    //   path: types.REACT_ROOT + types.ALL_MARKETS + types.VIEWS_HISTORY,
    //   name: "Views Analyser",
    //   component: ViewsAnalyser
    // },
    // {
    //   path: types.REACT_ROOT + types.ALL_MARKETS + types.LIVE_VIEWS,
    //   name: "Live Views",
    //   component: LiveViews
    // }
  ]
};

const logVisualiserRoutes = {
  path: types.REACT_ROOT + types.LOG_VISUALISER,
  name: "Log Visualiser",
  icon: BarChart2Icon,
  component: LogVisualiser,
  children: null
};

const logParserRoutes = {
  path: types.REACT_ROOT + types.LOG_PARSER,
  name: "Log Parser",
  icon: SettingsIcon,
  component: LogParser,
  children: null
};

const capitalAccountsRoutes = {
  path: types.REACT_ROOT + types.CAPITAL_ACCOUNTS,
  name: "Capital Accounts",
  icon: TrendingUpIcon,
  component: CapitalAccounts,
  children: null
};

const riskControlsRoutes = {
  path: types.REACT_ROOT + types.RISK_CONTROLS,
  name: "Controls",
  icon: AlertTriangleIcon,
  component: RiskControls,
  children: null
};

const riskMonitorRoutes = {
  path: types.REACT_ROOT + types.RISK_MONITOR,
  name: "Risk Monitor",
  icon: AlertTriangleIcon,
  component: RiskMonitor,
  children: null
};

const macroEventsRoutes = {
  path: types.REACT_ROOT + types.EVENTS + types.MACRO_EVENTS,
  name: "Macro Events",
  icon: CalendarIcon,
  component: MacroEvents,
  children: null
};

const eventsRoutes = {
  path: types.REACT_ROOT + types.EVENTS,
  name: "Events",
  icon: CalendarIcon,
  component: MacroEvents,
  children: [
    {
      path: types.REACT_ROOT + types.EVENTS + types.MACRO_EVENTS,
      name: "Macro Events",
      component: MacroEvents
    },
    {
      path: types.REACT_ROOT + types.EVENTS + types.SCHEDULED_EVENTS,
      name: "Scheduled Events",
      component: ScheduledEvents
    },
  ]
};

const viewsRoutes = {
  path: types.REACT_ROOT + types.VIEWS,
  name: "Views",
  icon: EyeIcon,
  children: [
    {
      path: types.REACT_ROOT + types.VIEWS + types.VIEWS_HISTORY,
      name: "Views Analyser",
      component: ViewsAnalyser
    },
    {
      path: types.REACT_ROOT + types.VIEWS + types.VIEWS_EDITOR,
      name: "Views Editor",
      component: ViewsEditor
    },
    {
      path: types.REACT_ROOT + types.VIEWS + types.LIVE_VIEWS,
      name: "Live Views",
      component: LiveViews
    }
  ]
};

// dashboard specific routes
export const dashboard = [
  allMarketRoutes,
  sofrRoutes,
  //eurodollarRoutes,
  viewsRoutes,
  riskMonitorRoutes,
  macroEventsRoutes,
  eventsRoutes,
  capitalAccountsRoutes,
  logVisualiserRoutes,
  logParserRoutes,
  riskControlsRoutes,
  notificationsRoutes,
  termsRoutes
];

// auth specific routes
export const page = [authRoutes];

// all routes
export const admin_routes = [
  allMarketRoutes,
  //eurodollarRoutes,
  sofrRoutes,
  riskControlsRoutes,
  logVisualiserRoutes,
  logParserRoutes,
//   capitalAccountsRoutes,
  eventsRoutes
];

export const investor_routes = [
  allMarketRoutes,
  sofrRoutes,
  riskControlsRoutes,
  logVisualiserRoutes,
  logParserRoutes,  
//   capitalAccountsRoutes,
  eventsRoutes
];