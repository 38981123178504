// sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// github wiki links
export const LAUNCH_CONTROLS_WIKI = "https://github.com/deepgreyresearch/dgr_release_cme_ed/wiki/Launch-Controls-Guide"
export const POSITIONS_STATUS_WIKI = "https://github.com/deepgreyresearch/dgr_release_cme_ed/wiki/Positions-Status-Guide"
export const SESSION_CONTROLS_WIKI = "https://github.com/deepgreyresearch/dgr_release_cme_ed/wiki/Session-Controls-Guide"
export const ZFWK_CONTROLS_WIKI = "https://github.com/deepgreyresearch/dgr_release_cme_ed/wiki/ZFWK-Controls-Guide"

// get env var route
var dash_url = undefined;
var product = /.com([^]+\/)/.exec(window.location.href);
if (product != null) {
    product = product[1];
} else {
    product = ""
}
if (process.env.NODE_ENV === 'development') {
    dash_url =  "http://localhost:8050/api/" + product;
}
else {
    const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
    dash_url = "https://" + domain + "/api/" + product;
}

// backend (dash) routes
export const DASH_ROOT = dash_url;
export const LOG_VISUALISER_ROOT = "https://trade.deepgreymarkets.com/log_visualiser/";
export const ACCOUNTS_ROOT = "https://trade.deepgreymarkets.com/accounts/";
export const ED_CHART = "ed_chart#";
export const ED_LIVE_CHART = "ed_live_chart#";
export const INVENTORY_CHART = "inventory_chart#";
export const EIG_POS_CHART = "eig_pos_chart#";
export const MATCHER_INVENTORY_CHART = "matcher_inventory_chart#";
export const HISTORICAL_POSITIONS_CHART = "historical_positions_chart#";
export const TRADES_DONE_TABLE = "trades_done_table#";
export const ROLLING_PNL_CHART = "rolling_pnl_chart#";
export const EIG_ATTRIBUTION_CHART = "eig_attribution_chart#";
export const VAR_99_CHART = "var_99_chart#";
export const SPAN_CHART = "span_chart#";
export const BIDASK_SPREAD_CHART = "bidask_spread_chart#";
export const BIDASK_FILL_RATES_CHART = "bidask_fill_rates_chart#";
export const PASSIVE_QUANTITY_CHART = "passive_quantity_chart#";
export const AGGRESSIVE_QUANTITY_CHART = "aggressive_quantity_chart#";
export const SPREAD_CHART = "spread_chart#";
export const FILL_RATES_CHART = "fill_rates_chart#";
export const INTENTIONS_TABLE = "intentions_table#";
export const HEALTH_STATUS_DASHBOARD = "health_status_dashboard#";
export const ZFWK_CONTROLS_DASHBOARD = "zfwk_controls_dashboard#";
export const SESSION_CONTROLS_DASHBOARD = "session_controls_dashboard#";
export const ENDPOINT_STATUS_DASHBOARD = "endpoint_status_dashboard#";
export const CONNECTION_DASHBOARD = "connection_dashboard#";
export const PP_TRACKING_DASHBOARD = "pp_tracking_dashboard#";
export const LAUNCH_CONTROLS_DASHBOARD = "launch_controls_dashboard#";
export const POSITIONS_DASHBOARD = "positions_dashboard#";
export const ERRORS_TABLE = "errors_table#";
export const C_ERRORS_TABLE = "c_errors_table#";
export const DERISK_CHART = "derisk_chart#";
export const VIEWS_CHART = "views_chart#";
export const PRORATA_SIZE_CHART = "prorata_size_chart#";
export const PRORATA_LEVEL_CHART = "prorata_level_chart#";
export const PRORATA_LIVE_LEVEL_CHART = "prorata_live_level_chart#";
export const PRORATA_TABLE = "prorata_table#";
export const DV01_TABLE = "dv01_table#";
export const DGM_NAV_CHART = "dgm_nav_chart#"
export const DGM_NAV_BREAKDOWN_CHART = "dgm_nav_breakdown_chart#"
export const DGR_FEES_CHART = "dgr_fees_chart#"
export const ALLOCATIONS_TABLE = "allocations_table#"
export const CLASS_A_CHART = "class_a_chart#"
export const CLASS_B_CHART = "class_b_chart#"
export const DGM_ASSETS_CHART = "dgm_assets_chart#"
export const LOG_VISUALISER_CHART = "log_visualiser_chart#"
export const ORDER_ANALYSER_DASHBOARD = "past_live_orders_tables#"
export const GC2_CONSUMPTION_CHART = "gc2_consumption_chart#"
export const MOD_CANCEL_ORDER_CHART = "mod_cancel_order_chart#"
export const PRORATA_UPDATES_SIZE_LEVELS_CHART = "prorata_updates_size_levels_chart#"
export const LOG_PARSER_DASHBOARD = "log_parser_dashboard#";
export const DERISK_TABLE = "derisk_table#";
export const UPCOMING_EVENTS_TABLE = "upcoming_events_table#";
export const BACKEND_STATUS_DASHBOARD = "backend_status_dashboard#";
export const BINARY_SELECTOR_DASHBOARD = "binary_selector_dashboard#";
export const CONNECTOR_ERROR_DASHBOARD = "connectors_error#";
export const DAILY_VOLUME_TABLE = "daily_volume_table#";
export const USER_ACTIVITY_TABLE = "user_activity_table#";
export const CONFIG_VIEWER = "config_viewer#";
export const VIEWS_LIVE_CHART = "views_live_chart#";
export const PYTHON_LOGS_TABLE = "python_logs_table#";
export const SCHEDULED_STOP_DASHBOARD = "schedule_stop_dashboard#";
export const VIEWS_DATA_TABLE = "views_data_table#";
export const VIEWS_HISTORY_CHART = "views_factor#";
export const VIEWS_INPUT_DASHBOARD = "views_input_dashboard#";
export const FACTOR_CHART = "factor_chart#";
export const DAILY_MOD_CHART = "daily_mod_chart#";
export const VIEWS_PROCESS_DASHBOARD = "views_process_dashboard#";

// frontend (react) routes
export const REACT_ROOT = "/";
export const AUTH = "auth"
export const SIGN_IN = "auth/sign-in";
export const PAGE_404 = "auth/404";
export const PAGE_500 = "auth/500";
export const NOTIFICATIONS = "notifications"
export const TERMS = "termsofservice";
export const CONTROL = "control";
export const PRORATA = "prorata";
export const INTENTIONS = "intentions";
export const PNL = "pnl";
export const CAPITAL_ACCOUNTS = "capital_accounts";
export const LOG_VISUALISER = "logs_visualiser";
export const RISK_MONITOR = "risk_monitor";
export const RISK_CONTROLS = "risk_controls";
export const JUPYTER = "jupyter";
export const ORDER_ANALYSER = "order_analyser";
export const TRADE_MONITOR = "trade_monitor";
export const LOG_PARSER = "log_parser";
export const MACRO_EVENTS = "macro_events";
export const SCHEDULED_EVENTS = "scheduled_events";
export const LIVE_VIEWS = "live_views";
export const VIEWS_PARSER = "views_parser";
export const VIEWS_HISTORY = "views_history";
export const VIEWS_EDITOR = "views_editor";

export const ALL_MARKETS = "all/";
export const EURODOLLAR = "ed/";
export const SOFR = "sofr/";
export const SYSTEM_OVERVIEW = "system_overview/";
export const DIAGNOSTICS = "diagnostics/";
export const EVENTS = "events/";
export const VIEWS = "views/";

// permissions
export const MIN_ADMIN_PERMISSIONS = 1;

